($ => {

    /**
     * Navigation DOM
     *
     * @type {jQuery}
     */
    const $navigation = $('.navigation');

    /**
     * Navigation button DOM
     *
     * @type {jQuery}
     */
    const $navigationButton = $('.navigation-button');

    /**
     * Navigation more button DOM.
     *
     * @type {jQuery}
     */
    const $navigationMore = $navigation.find('.navigation__more');

    /**
     * Navigation more button DOM.
     *
     * @type {jQuery}
     */
    const $navigationXMore = $navigation.find('.navigation__xmore');

    /**
     * Navigation back button DOM.
     *
     * @type {jQuery}
     */
    const $navigationBack = $navigation.find('.navigation__back');

    /**
     * Handles the actions to take when the menu button is clicked.
     *
     * @param {Event} event - jQuery event object
     */
    const handleButtonClick = event => {
        event.preventDefault();
        $navigation.toggleClass('navigation--active');
        $alertServiceGlobal.removeClass('alert-service-global--active');
    };

    /**
     * Handles the actions to take when the more button is clicked.
     */
    const handleMoreClick = function() {
        const $this = $(this);
        $this.closest('.navigation__list').addClass('navigation__list--hide');
        $this.parent().find('.navigation__list').first().addClass('navigation__list--show');
    };

    /**
     * Handles the actions to take when the back button is clicked.
     */
    const handleBackClick = function() {
        const $this = $(this);
        $this.closest('.navigation__list--show').removeClass('navigation__list--show');
        $this.closest('.navigation__list--hide').removeClass('navigation__list--hide');
    };

    $navigationButton.on('click', handleButtonClick);
    $navigationMore.on('click', handleMoreClick);
    $navigationXMore.on('click', handleMoreClick);
    $navigationBack.on('click', handleBackClick);

    // An issue with IE not rendering anything hidden correctly we need to
    // force re-rendering on those objects
    if (/Edge/.test(navigator.userAgent)) {
        let menuClone, parent;
        $('.navigation__item').each(function() {
            let $this = $(this);
            parent = $this.parent();
            menuClone = $this.clone();
            $this.remove();
            menuClone.appendTo(parent);
        });
    }

})(jQuery);
