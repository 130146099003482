($ => {

    /**
     * There's a draw issue in Safari with button that react to select boxes.
     * A hacky fix for it is to focus on the button after a select has changed
     * to redraw the button. As this is a Safari only issue, we only want to
     * fire this in Safari.
     *
     * @see https://bitbucket.org/21sixDigital/jext.co.uk/issues/79/
     */

    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        $('select').on('change', event => {
            $(event.target)
                .closest('.module')
                .find('.button')
                .focus();
        });
    }

})(jQuery);
