($ => {

    /**
     * Default options for all carousels.
     */
    const defaultCarouselOptions = {
        dots: false,
        loop: true,
        nav: true,
        items: 1,
    };

    /**
     * Kids hero carousel
     */
    $('.jext--kids .carousel').owlCarousel(Object.assign(defaultCarouselOptions, {
        navText: [
            '<svg width="100%" height="100%" viewBox="0 0 120 162" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"><g><path d="M83.838,4.995c-2.2,-2.205 -5.772,-2.205 -7.971,0l-71.805,71.972c-2.2,2.205 -2.2,5.785 0,7.99l32.176,32.251c2.199,2.205 5.771,2.205 7.971,0l71.805,-71.972c2.2,-2.205 2.2,-5.785 0,-7.99l-32.176,-32.251Z"/><path d="M115.938,124.754c2.2,-2.205 2.2,-5.785 0,-7.99l-71.805,-71.972c-2.199,-2.205 -5.771,-2.205 -7.971,0l-32.176,32.251c-2.2,2.205 -2.2,5.785 0,7.99l71.805,71.972c2.2,2.205 5.772,2.205 7.971,0l32.176,-32.251Z"/></g></svg>',
            '<svg width="100%" height="100%" viewBox="0 0 120 162" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"><g><path d="M36.162,157.005c2.2,2.205 5.772,2.205 7.971,0l71.805,-71.972c2.2,-2.205 2.2,-5.785 0,-7.99l-32.176,-32.251c-2.199,-2.205 -5.771,-2.205 -7.971,0l-71.805,71.972c-2.2,2.205 -2.2,5.785 0,7.99l32.176,32.251Z"/><path d="M4.062,37.246c-2.2,2.205 -2.2,5.785 0,7.99l71.805,71.972c2.199,2.205 5.771,2.205 7.971,0l32.176,-32.251c2.2,-2.205 2.2,-5.785 0,-7.99l-71.805,-71.972c-2.2,-2.205 -5.772,-2.205 -7.971,0l-32.176,32.251Z"/></g></svg>',
        ],
    }));

    /**
     * Teens hero carousel
     */
    $('.jext--teens .carousel').owlCarousel(Object.assign(defaultCarouselOptions, {
        navText: [
            '<img class="carousel__arrow" src="/themes/teens/images/arrow/arrow.png" style="transform: rotate(180deg);" />',
            '<img class="carousel__arrow" src="/themes/teens/images/arrow/arrow.png" />',
        ],
    }));

    /**
     * Adults hero carousel
     */
    $('.jext--adults .carousel').owlCarousel(Object.assign(defaultCarouselOptions, {
        navText: [
            '<img class="carousel__arrow" src="/themes/adults/images/carousel-arrow.jpg" style="transform: rotate(180deg);" />',
            '<img class="carousel__arrow" src="/themes/adults/images/carousel-arrow.jpg" />',
        ],
    }));

    /**
     * HCP hero carousel
     */
    $('.jext--hcp .carousel').owlCarousel(Object.assign(defaultCarouselOptions, {
        navText: [
            '<img class="carousel__arrow" src="/themes/hcp/images/carousel-arrow.png" style="transform: rotate(180deg);" />',
            '<img class="carousel__arrow" src="/themes/hcp/images/carousel-arrow.png" />',
        ],
    }));

})(jQuery);
