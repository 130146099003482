($ => {

    const $banner = $('.banner');
    const $bannerClose = $banner.find('.banner__close');

    $bannerClose.on('click', event => {
        event.preventDefault();
        window.sessionStorage.setItem('banner-closed', true);
        $banner.removeClass('banner--active');
    });

    $(document).ready(() => {
        if (window.sessionStorage.getItem('banner-closed')) {
            $banner.removeClass('banner--active');
        }
    });

})(jQuery);
