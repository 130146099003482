($ => {

    /**
     * Toggleable DOM
     *
     * @type {jQuery}
     */
    const $toggleable = $('.toggleable');

    const $toggleableNotFirstChild = $('.toggleable:not(.toggleable:first-of-type)');

    const $toggleableCTA = $toggleable.find('> .toggleable__header');

    $toggleableNotFirstChild.addClass('toggleable--inactive');

    $toggleableCTA.on('click', function() {
        $(this).parent().toggleClass('toggleable--inactive');
    });

})(jQuery);
