($ => {
    const $resourceOrderForm = $('.resource-order-form');
    const resourceOrderUrl = $resourceOrderForm.attr('action');
    const resourceOrderMethod = $resourceOrderForm.attr('method');

    $resourceOrderForm.submit(event => {
        event.preventDefault();
        $.ajax({
            url: resourceOrderUrl,
            type: resourceOrderMethod,
            data: $resourceOrderForm.serialize(),
            success: response => {
                $resourceOrderForm.html('<h2 style="text-align: center;padding-top: 2em; padding-bottom: 2em;">' + response + '</h2>');
            },
            error: response => {
                alert(response.responseText);
            },
        });

    });

})(jQuery);
