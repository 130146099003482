($ => {

    const defaultValue = '--';
    const placeholderClass = 'select-placeholder';

    const handleSelectChange = event => {
        const $this = $(event.target);
        const placeholderValue = $this.data('placeholder') || defaultValue;

        if ($this.val() !== placeholderValue) {
            $this.removeClass(placeholderClass);
            $this.closest('.mini-module').find('[download]').html('Download');
        } else {
            $this.addClass(placeholderClass);
            $this.closest('.mini-module').find('[download]').html('Select a language');
        }
    };

    $(() => {
        const $selects = $('select');
        $selects.each(function() {
            const $this = $(this);
            if (! $this.parent().hasClass('select')) {
                let placeholderValue = defaultValue;
                if ($this.find('[data-placeholder]')[0]) {
                    placeholderValue = $this.find('[data-placeholder]')[0].value;
                    $this.addClass(placeholderClass);
                }
                $this.data('placeholder', placeholderValue);
                $this.wrap('<div class="select"></div>');
            }

            $this.on('change', handleSelectChange);
        });
    });

    $('[data-download-list]').on('click', event => {
        const downloadId = $(event.target).data('download-list');
        const value = $('#download-' + downloadId).val();
        const title = $('#download-' + downloadId).closest('article').find('h2').text();
        const lang = $('#download-' + downloadId + ' option:selected').text();

        event.preventDefault();

        if (value === defaultValue) {
            alert('Please select a language before downloading...');
        } else {
            var data = {
                'action': 'sheet_dl_tick',
                'file': title,
                'lang': lang,
                'src': window.location.href,
            };
            $.post(ajaxurl, data).done(function() {
                console.log(data);
            });
            let downloadLink = $('#download-' + downloadId).val().toString();
            let win = window.open(downloadLink, '_blank');
            win.focus();
        }
    });

})(jQuery);
