($ => {

    // Display Alert Service
    // =========================================================================

    const $alertServiceGlobal = $('.alert-service-global');

    const toggleAlertServiceGlobalForm = () => {
        $alertServiceGlobal.toggleClass('alert-service-global--active');
        $('.navigation').removeClass('navigation--active');
        $('body').toggleClass('overflow-hidden');
    };

    $('.alert-service-global__title, .alert-service-global__toggle-button, .alert-expiry-button').on('click', toggleAlertServiceGlobalForm);
    $('.jext--adults #mini-module-2195-4 .button--order-4').on('click', toggleAlertServiceGlobalForm);

    $(document).mouseup(function (e) {
        if ($(e.target).closest($alertServiceGlobal).length === 0) {
            $alertServiceGlobal.removeClass('alert-service-global--active');
            $('body').toggleClass('overflow-hidden');
        }
    });

    $('body').find('[href="#expiry-alert-register"]')
        .on('click', toggleAlertServiceGlobalForm);

    // Alert Service Register and Unregister
    // =========================================================================

    const formSubmit = event => {
        const $this = $(event.target);
        event.preventDefault();
        $.ajax({
            url: $this.attr('action'),
            type: $this.attr('method'),
            data: $this.serialize(),
            success: response => {
                $this.html('<h2 style="text-align: center;">' + response + '</h2>');
            },
            error: response => {
                alert(response.responseText);
            },
        });
    };

    $('.alert-service').on('submit', formSubmit);
    $('.expiry-alert-service-form').on('submit', formSubmit);
    $('.alert-service-unregister').on('submit', formSubmit);

})(jQuery);
