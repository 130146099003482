// =============================================================================
// Jext
// =============================================================================

// Import
// =============================================================================

// External
import 'owl.carousel/dist/owl.carousel';

// Local
import './component/resource-order';
import './component/alert-service';
import './component/select-focus';
import './component/navigation';
import './component/carousel';
import './component/banner';
import './component/select';
import './component/toggle';

// Code
// =============================================================================

($ => {
    $('body').removeClass('no-js').addClass('js');
})(jQuery);
